<template>
  <dog-dialog
    ref="dogDialog"
    title="设置发展历程"
    @loading-confirm="confirm"
    @closed="closed"
    width="900px"
  >
    <div class="editHistoryDialog">
      <div class="single_add" v-if="formData.history.length < 1">
        <i class="el-icon-circle-plus" @click="addFormData(index)"></i>
      </div>
      <div v-for="(data, index) in formData.history">
        <div class="add_delete">
          <i class="el-icon-circle-plus" @click="addFormData(index)"></i>
          <i class="el-icon-remove" @click="deleteFormData(index)"></i>
        </div>
        <dog-form
          ref="dogForm"
          :form-data="data"
          :column-num="2"
          :col-rule="(row) => row.col || 12"
          :form-options="formOptions"
        ></dog-form>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import companyService from '@/api/service/company.js';
  import singleImgUpload from '@/components/singleImgUpload.vue';

  export default {
    name: 'editHistoryDialog',
    data() {
      return {
        companyId: null,
        formData: {
          history: [{}]
        },
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '标题',
              prop: 'title',
              rules: [this.$formRules.required('请输入标题')]
            }
          },
          {
            type: 'date',
            formItem: {
              prop: 'date',
              label: '日期',
              rules: [this.$formRules.required('请选择日期')]
            }
          },
          {
            type: 'custom',
            formItem: {
              prop: 'img',
              label: '图片',
              rules: [this.$formRules.required('请上传图片')]
            },
            component: singleImgUpload
          },
          {
            type: 'input',
            formItem: {
              label: '简介',
              prop: 'desc',
              rules: [this.$formRules.required('请输入简介')]
            },
            attrs: {
              type: 'textarea',
              rows: 3
            },
            col: 24
          }
        ]
      };
    },
    methods: {
      openFrame(id) {
        this.companyId = id;
        this.$refs.dogDialog.open();
        companyService.getCompanyHistory({ companyId: id }).then((res) => {
          this.formData.history = res.length === 0 ? [{}] : res;
        });
      },
      addFormData(index) {
        this.formData.history.splice(index, 0, {});
      },
      deleteFormData(index) {
        this.formData.history.splice(index, 1);
      },
      confirm(done) {
        const formList = [...this.$refs.dogForm];
        Promise.all(
          formList.map((item) => {
            return item.validate();
          })
        )
          .then(() => {
            return companyService.setCompanyHistory({
              ...this.formData,
              companyId: this.companyId
            });
          })
          .then(() => {
            this.close();
            this.$emit('confirm');
          })
          .finally(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.companyId = null;
        this.formData = { history: [{}] };
      }
    }
  };
</script>

<style lang="less" scoped>
  .single_add {
    text-align: right;
  }
  .add_delete {
    margin-bottom: 10px;
    text-align: right;
  }
  .el-icon-circle-plus {
    font-size: 25px;
    color: #0080ff;
    cursor: pointer;
  }
  .el-icon-remove {
    margin-left: 5px;
    font-size: 25px;
    color: red;
    cursor: pointer;
  }
</style>
